/* eslint-disable */
module.exports = {
  session: {
    create: true,
  },
  account: {
    create: true,
    update: true,
    destroy: true,
    find: true,
    resetPassword: true,
    activate2fa: true,
    reset2fa: true,
    deactivate2fa: true,
    convertToOrganization: true,
  },
  sites: {
    find: true,
    all: true,
    create: true,
    update: true,
    destroy: true,
    duplicate: true,
  },
  siteSubscription: {
    create: true,
    simulate: true,
    validate: true,
  },
  perOwnerPricingSubscription: {
    create: true,
    simulate: true,
    validate: true,
  },
  sitePlans: {
    all: true,
  },
  perOwnerPricingPlans: {
    all: true,
    activableAsAppointedOrganizationInstances: true,
  },
  perSitePricingBillingProfiles: {
    all: true,
    find: true,
    updateCreditCard: true,
    updateInfo: true,
    destroy: true,
  },
  perOwnerPricingBillingProfile: {
    find: true,
    updateCreditCard: true,
    updateInfo: true,
  },
  invoice: {
    perOwnerPricingBillingProfileInstances: true,
    perSitePricingBillingProfileInstances: true,
    perOwnerPricingBillingProfileCollectUnpaid: true,
    perSitePricingBillingProfileCollectUnpaid: true,
  },
  resourceUsages: {
    all: true,
  },
  jobResult: {
    find: true,
  },
  siteTransfers: {
    all: true,
    find: true,
    create: true,
    destroy: true,
    simulateAccept: true,
    accept: true,
    decline: true,
  },
  siteInvitation: {
    redeem: true,
  },
  subscriptionLimits: {
    all: true,
  },
  subscriptionFeatures: {
    all: true,
  },
  oauthApplications: {
    all: true,
    destroy: true,
  },
  paymentIntent: {
    createFromPaymentMethod: true,
    createForPerSitePricingBillingProfile: true,
    createForPerOwnerPricingBillingProfile: true,
    confirm: true,
  },
  organizations: {
    all: true,
    find: true,
    create: true,
    update: true,
    destroy: true,
  },
  organizationRoles: {
    all: true,
    find: true,
  },
  organizationInvitations: {
    create: true,
    update: true,
    all: true,
    find: true,
    destroy: true,
    resend: true,
    redeem: true,
  },
  organizationMemberships: {
    update: true,
    all: true,
    find: true,
    me: true,
    destroy: true,
  },
  organizationMandateRequest: {
    create: true,
    pendingInstances: true,
    requestedInstances: true,
    destroy: true,
    approve: true,
    reject: true,
  },
  organizationMandate: {
    update: true,
    approvedInstances: true,
    givenInstances: true,
    destroy: true,
  },
};
